import { Injectable, WritableSignal, computed, inject, signal } from '@angular/core';
import { filter, finalize, map, of, tap, zip } from 'rxjs';
import { SignalsStoreService } from '../shared/signals-store.service';
import { ApiService } from '../shared/api.service';
import { BundleItems, FilterParams, PreOrderData, Product, ProductResume, selectionProducts, Tag } from './product.types';
import { RequestHandlerParams } from '../shared/types/api-service.types';
import { ApiResponse } from '../shared/common/types';
import { SidebarService } from '../shared/sidebar/sidebar.service';
import { SidebarCheckList, SidebarFilterActions, SidebarFilterTypes } from '../shared/sidebar/sidebar.types';
import { NotificationService } from '../shared/notification/notification.service';
import { BundleEditionType } from '../shared/types/order.type';
import { formatDateToReadableString } from '../shared/utils/formatting';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {
  private signalsStoreService = inject(SignalsStoreService)
  private apiService = inject(ApiService);
  private sidebarService = inject(SidebarService);
  private notififacionService = inject(NotificationService);

  endpoints = {
    products: `/products`,
    productsByCategory: `/categories`,
    productsByCategoryIdResume: `/categories-resume`,
    signupProducts: `/products/sign-up`,
    favorites: `/products/favorites`,
    dislike: `/products/dislikes`,
    dislikeWithSuggestion: `/products/dislikes/suggestions`,
    buyAgain: `/products/buy-again`,
    stock: `/stock`,
    bundleSubstitutions: '/bundle-substitutions',
    bestSellers: '/products/best-sellers',
    bundles: '/bundle'
  }

  isLoading: WritableSignal<boolean> = signal(false);
  isLoaded: WritableSignal<boolean> = signal(false);
  productSignal: WritableSignal<any> = signal(null)
  productsSignal: WritableSignal<ProductResume[]> = signal([]);
  private products: ProductResume[] = [];
  productsSelectionListSignal: WritableSignal<selectionProducts> = signal({ onetime: [], subscription: [] });

  tagsSignal: WritableSignal<Tag[]> = signal([]);
  filteringTags = computed(() => {
    const tags = this.tagsSignal();

    return tags.filter(tag => tag.isChecked());
  })

  getProducts(categoryId: number, isRecommended = false) {
    // Vaciamos stock:
    this.clearProductsData();
    this.isLoading.set(true);
    const params: RequestHandlerParams = {
      method: 'GET',
      endpoint: `${this.endpoints.productsByCategory}/${categoryId}?isRecomended=${isRecommended ? 1 : 0}`,
      apiV3: true
    };
    return this.apiService.handleRequest<ApiResponse<{ sidebar: any, products: any[] }>>(params)
      .pipe(
        filter((response: ApiResponse<{ sidebar: any, products: any[] }>) => !!response.data && !!response.data.products?.length),
        tap((response: ApiResponse<{ sidebar: any, products: any[] }>) => this.setUpProductsResponse(response.data)),
        finalize(() => {
          this.isLoading.set(false);
          this.isLoaded.set(true);
        })
      ).subscribe();
  }

  getProductsDetailsByIds(productsIds: number[]) {
    const params: RequestHandlerParams = {
      method: 'POST',
      endpoint: this.endpoints.products,
      apiV3: true,
      body: { productsIds }
    };
    return this.apiService.handleRequest<ApiResponse<Product[]>>(params).pipe(
      filter((response: ApiResponse<Product[]>) => !!response.data?.length),
      map((response: ApiResponse<Product[]>) => response.data),
      finalize(() => {
        this.isLoading.set(false);
        this.isLoaded.set(true);
      })
    )
  }

  getProductById(productId: number, type?: BundleEditionType) {
    this.productSignal.set(null);
    const params: RequestHandlerParams = {
      method: 'GET',
      endpoint: `${this.endpoints.products}/${productId}?type=${type ?? ''}`,
      apiV3: true
    }

    this.signalsStoreService.isContentLoaded.set(false)

    return this.apiService.handleRequest<ApiResponse<Product>>(params).pipe(
      filter((response: ApiResponse<Product>) => !!response.data),
      tap(({ data: product }: ApiResponse<Product>) => {
        product.price = parseFloat(product.price.toString()).toFixed(2);
        this.#setUpIndividualProductResponse(product);
        this.signalsStoreService.isContentLoaded.set(true);
      }),
    )
  };

  getProductByUrl(seoUrl: string) {
    this.productSignal.set(null);
    const params: RequestHandlerParams = {
      method: 'GET',
      endpoint: `/products/${seoUrl}`,
      apiV3: true
    }

    this.signalsStoreService.isContentLoaded.set(false)

    return this.apiService.handleRequest<ApiResponse<Product>>(params).pipe(
      filter((response: ApiResponse<Product>) => !!response.data),
      tap(({ data: product }: ApiResponse<Product>) => {
        product.price = parseFloat(product.price.toString()).toFixed(2);
        this.#setUpIndividualProductResponse(product);
        this.signalsStoreService.isContentLoaded.set(true);
      })
    );
  };

  getProductsResume(categoryId: number) {
    this.clearProductsData();
    this.isLoading.set(true);
    const params: RequestHandlerParams = {
      method: 'GET',
      endpoint: `${this.endpoints.productsByCategoryIdResume}/${categoryId}`,
      apiV3: true
    };

    return this.apiService.handleRequest<ApiResponse<{ sidebar: any, products: ProductResume[] }>>(params).pipe(
      filter((response: ApiResponse<{ sidebar: any, products: ProductResume[] }>) => !!response.data && !!response.data.products?.length),
      tap((response: ApiResponse<{ sidebar: any, products: ProductResume[] }>) => this.setUpProductsResponse(response.data)),
      finalize(() => {
        this.isLoading.set(false);
        this.isLoaded.set(true);
      })
    ).subscribe();
  }

  getSignupProducts() {
    this.clearProductsData();
    this.isLoading.set(true);
    const params: RequestHandlerParams = {
      method: 'GET',
      endpoint: this.endpoints.signupProducts,
      apiV3: true
    };
    return this.apiService.handleRequest<ApiResponse<Product[]>>(params).pipe(
      filter((response: ApiResponse<Product[]>) => !!response.data)
    );
  }

  getFavoritesDislikes(setupLoading: boolean = true) {
    if (setupLoading) {
      this.isLoaded.set(false);
      this.isLoading.set(true);
    }

    return zip(
      this.#getFavorites(),
      this.#getDislikes()
    )
      .pipe(
        finalize(() => {
          if (setupLoading) {
            this.isLoading.set(false);
            this.isLoaded.set(true);
          }
        })
      );
  }

  #getFavorites() {

    const params: RequestHandlerParams = {
      method: 'GET',
      endpoint: this.endpoints.favorites,
      apiV3: true
    };

    return this.apiService.handleRequest<ApiResponse<Product[]>>(params);
  }

  #getDislikes() {

    const params: RequestHandlerParams = {
      method: 'GET',
      endpoint: this.endpoints.dislike,
      apiV3: true
    };

    return this.apiService.handleRequest<ApiResponse<Product[]>>(params);
  }

  getBuyAgain() {
    this.clearProductsData();
    this.isLoading.set(true)
    const params: RequestHandlerParams = {
      method: 'GET',
      endpoint: this.endpoints.buyAgain,
      apiV3: true
    };
    return this.apiService.handleRequest<ApiResponse<Product[]>>(params).pipe(
      filter((response: ApiResponse<Product[]>) => !!response.data?.length),
      tap((response: ApiResponse<Product[]>) => this.setUpSelectionProducts(response.data)),
      finalize(() => {
        this.isLoading.set(false);
        this.isLoaded.set(true);
      })
    );
  }

  private setUpSelectionProducts(data: any) {
    let products: selectionProducts = {
      onetime: [],
      subscription: []
    };
    if (data.length) {
      for (const product of data) {
        if (product.isSubscription)
          products.subscription.push(product);
        else
          products.onetime.push(product);
      }
    }
    this.productsSelectionListSignal.set(products);
  }

  getProductsByFilter(filterParams: FilterParams[]) {
    // Vaciamos stock:
    this.clearProductsData();
    this.isLoading.set(true);
    const queryParams = this.setUpFilterQueryParams(filterParams);
    const params: RequestHandlerParams = {
      method: 'GET',
      endpoint: `/categories-resume${queryParams}`,
      apiV3: true
    };
    return this.apiService.handleRequest<ApiResponse<{ sidebar: any, products: ProductResume[] }>>(params).pipe(
      filter((response: ApiResponse<{ sidebar: any, products: ProductResume[] }>) => !!response.data && !!response.data.products?.length),
      tap((response: ApiResponse<{ sidebar: any, products: ProductResume[] }>) => this.setUpProductsResponse(response.data)),
      finalize(() => {
        this.isLoading.set(false);
        this.isLoaded.set(true);
      })
    ).subscribe();
  }

  private setUpFilterQueryParams(filterParams: FilterParams[]): string {
    let query = '';
    let i = 0;
    for (const filter of filterParams) {
      query += i === 0 ? '?' : '&';
      query += `${filter.type}=${filter.values.join(';')}`;
      i++;
    }
    return query;
  }

  private setUpProductsResponse(data: { sidebar: any, products: ProductResume[] }, sidebarFilterAction?: SidebarFilterActions) {
    if (data.products && data.products.length) {
      this.signalsStoreService
        .categoryInViewPort
        .set({
          categoryId: +data.products[0].category.id,
          subCategoryId: +data.products[0].subcategory.id,
        });
    }

    this.productsSignal.set(data.products);
    this.products = data.products;
    this.sidebarService.setUpSidebarData({ sidebarData: data.sidebar, filterType: sidebarFilterAction, categoryId: this.signalsStoreService.selectedCategory().id });

    this.tagsSignal.set(data.sidebar.tags?.map((tag: any) => ({ ...tag, isChecked: signal(false) })) ?? []);
  }

  #setUpIndividualProductResponse(product: Product) {
    product.preOrder = this.setUpPreorderProductData(product.preOrder);
    this.productSignal.set(product);
  }

  setUpPreorderProductData(preOrder: PreOrderData | null): PreOrderData | null {
    if (!preOrder) return null;
    return {
      ...preOrder,
      _readableEndDate: formatDateToReadableString(preOrder.endDate),
      _readableStartDeliveryDate: formatDateToReadableString(preOrder.startDeliveryDate),
      _readableEndDeliveryDate: formatDateToReadableString(preOrder.endDeliveryDate)
    } as PreOrderData
  }

  filterBySelectedTags() {
    const tags = this.tagsSignal();
    if (!tags?.some(tag => tag.isChecked())) {
      // No tags are selected, return all products and full menu:
      this.productsSignal.set(this.products);
      this.sidebarService.setUpSidebarDataAfterFilter(null, true);
      return;
    }

    // Extract selected tag IDs into a Set for faster lookups
    const selectedTagIds = new Set(tags.filter(tag => tag.isChecked()).map(tag => tag.id));

    // If no tags are selected, return all products
    if (!selectedTagIds.size) {
      this.productsSignal.set(this.products);
      this.sidebarService.setUpSidebarDataAfterFilter(null, true);
      return;
    }

    // Filter products based on selected tags
    const filteredProducts = this.products.filter(product => {
      // Exclude products without tags
      if (!product.tags?.length) return false;

      // Check if all selected tags are present in the product's tags
      return Array.from(selectedTagIds).every(tagId =>
        product.tags?.some(productTag => productTag.id === tagId)
      );
    });

    // Update the signal with filtered products
    this.sidebarService.setUpSidebarDataAfterFilter(filteredProducts);
    this.productsSignal.set(filteredProducts);

  }

  filterBySidebarChange() {
    const sidebars = this.sidebarService.sidebar();
    if (!this.isAnyChecked(sidebars)) return this.restoreProductsAndSidebar();

    if (!sidebars) return;
    let flattenedDietRestrictions: any[] = [];
    let flattenedTags: any[] = [];

    const dietRestrictions = sidebars.find(sidebar => sidebar.id === SidebarFilterTypes.dietRestrictions);
    if (dietRestrictions?.sublist)
      flattenedDietRestrictions = dietRestrictions?.sublist
        .filter(tag => tag.checked)
        .map(tag => tag.id) ?? []

    const tags = sidebars.find(sidebar => sidebar.id === SidebarFilterTypes.tags);
    if (tags?.sublist)
      flattenedTags = tags?.sublist
        .filter(tag => tag.checked)
        .map(tag => tag.id) ?? []

    const filterFunctions: any = {
      [SidebarFilterTypes.categories]: (sidebar: SidebarCheckList, product: any) => this.matchFilter(sidebar, product.category),
      [SidebarFilterTypes.subcategories]: (sidebar: SidebarCheckList, product: any) => this.matchFilter(sidebar, product.subcategory),
      [SidebarFilterTypes.producers]: (sidebar: SidebarCheckList, product: any) => this.matchFilter(sidebar, product.producer),
      [SidebarFilterTypes.dietRestrictions]: (sidebar: SidebarCheckList, product: any) => this.matchFilterArray(flattenedDietRestrictions, product.dietRestrictions),
      [SidebarFilterTypes.tags]: (sidebar: SidebarCheckList, product: any) => this.matchFilterArray(flattenedTags, product.tags),
    };

    const checkedSidebarItems = sidebars.map(item => {
      const filteredSublist = item.sublist?.filter(subitem => subitem.checked) || [];
      return filteredSublist.length > 0 ? { ...item, sublist: filteredSublist } : null;
    }).filter(item => item !== null);

    if (!checkedSidebarItems.length) return;

    const filteredProducts = this.products.filter((product: any) => {
      return checkedSidebarItems.every(sidebar => {
        if (sidebar) {
          const filterFuntion = filterFunctions[sidebar.id];
          return filterFuntion ? filterFuntion(sidebar, product) : false
        }
      });
    });

    this.sidebarService.setUpSidebarDataAfterFilter(filteredProducts);
    this.productsSignal.set(filteredProducts);
  }

  matchFilter(sidebar: SidebarCheckList, item: any) {
    return !!sidebar.sublist?.find(subitem => subitem.checked && subitem.id === item?.id);
  }

  matchFilterArray(flattenedArray: string[], productPropertyArray: any[]) {
    return flattenedArray.some(id => productPropertyArray?.map((t: any) => t.id).includes(id));
  }

  private restoreProductsAndSidebar() {
    this.productsSignal.set(this.products);
    this.sidebarService.sidebar.update(current =>
      current.map(e => ({
        ...e,
        disabled: false,
        sublist: e.sublist?.map(x => ({
          ...x,
          disabled: false
        }))
      }))
    );
  }

  private isAnyChecked(data: SidebarCheckList[]) {
    // Aplanar el array y extraer todos los elementos y sublistas
    const flattenedArray = data.flatMap(item => [item, ...(item.sublist || [])]);
    // Verificar si al menos uno de los elementos tiene "checked" como true
    return flattenedArray.some(item => item.checked);
  }

  setDislike(variantId: number, showMessage = true) {

    const params: RequestHandlerParams = {
      endpoint: `${this.endpoints.dislike}/${variantId}`,
      method: 'POST',
      apiV3: true,
      showErrorMessage: true
    };

    return this.apiService.handleRequest<ApiResponse<any>>(params).pipe(
      tap((response: ApiResponse<any>) => {
        if (response?.message && showMessage)
          this.notififacionService.show({ text: response.message, type: 'success' })
      })
    );
  }

  setDislikeWithSuggeestions(itemId: number, replacementId: number[], showMessage = true, isSubstitution = false) {

    const params: RequestHandlerParams = {
      endpoint: `${this.endpoints.dislikeWithSuggestion}/${itemId}`,
      method: 'POST',
      apiV3: true,
      showErrorMessage: true,
      body: { replacementId, isSubstitution }
    };

    return this.apiService.handleRequest<ApiResponse<{ suggestedProducts: BundleItems[] }>>(params).pipe(
      tap(response => {
        if (response?.message && showMessage)
          this.notififacionService.show({ text: response.message, type: 'success' })
      }),
      map(response => response.data.suggestedProducts?.map(p => { p.isSubstitutionProduct = true; return p }) || [])
    );
  }

  removeDislike(variantId: number, showMessage = true) {

    const params: RequestHandlerParams = {
      endpoint: `${this.endpoints.dislike}/${variantId}`,
      method: 'DELETE',
      apiV3: true,
      showErrorMessage: true,
      isVoid: true
    };

    return this.apiService.handleRequest<ApiResponse<any>>(params).pipe(
      tap((response: ApiResponse<any>) => {
        if (response?.message && showMessage)
          this.notififacionService.show({ text: response.message, type: 'success' });
      })
    )
  }

  setFavorite(variantId: number, showMessage = true) {

    const params: RequestHandlerParams = {
      endpoint: `${this.endpoints.favorites}/${variantId}`,
      method: 'POST',
      apiV3: true,
      showErrorMessage: true
    };

    return this.apiService.handleRequest<ApiResponse<any>>(params).pipe(
      tap((response: ApiResponse<any>) => showMessage ? this.notififacionService.show({ text: response.message, type: 'success' }) : null)
    )
  }

  removeFavorite(variantId: number, showMessage = true) {
    const params: RequestHandlerParams = {
      endpoint: `${this.endpoints.favorites}/${variantId}`,
      method: 'DELETE',
      apiV3: true,
      showErrorMessage: true,
      isVoid: true
    };

    return this.apiService.handleRequest<ApiResponse<any>>(params).pipe(
      tap((response: ApiResponse<any>) => {
        if (response?.message && showMessage)
          this.notififacionService.show({ text: response.message, type: 'success' });
      })
    );
  }

  private clearProductsData() {
    this.sidebarService.sidebar.set([]);
    this.productsSignal.set([]);
    this.products = [];
    this.productsSelectionListSignal.set({ onetime: [], subscription: [] });
  }

  goToSection(item: SidebarCheckList, event?: Event) {

    if (event && item.checked && item.sublist?.length)
      event.preventDefault();

    const parent = document.getElementById('layoutShopMain');
    const element = document.getElementById(item.key as string);

    if (element)
      parent?.scrollTo({
        top: element.offsetTop,
        left: 0,
        behavior: 'instant'
      });
  }

  getBundelItemsById(bundleId: number) {
    const params: RequestHandlerParams = {
      endpoint: `${this.endpoints.products}${this.endpoints.bundles}/${bundleId}`,
      method: 'GET',
      apiV3: true,
      returnError: true
    };

    return this.apiService.handleRequest<ApiResponse<BundleItems[]>>(params).pipe(map(res => res.data));
  }

  getBestSellingProducts(limit: number) {

    this.clearProductsData();
    this.isLoading.set(true);
    this.isLoaded.set(false);

    const params: RequestHandlerParams = {
      method: 'GET',
      endpoint: this.endpoints.bestSellers + `?limit=${limit}`,
      apiV3: true
    };

    return this.apiService.handleRequest<ApiResponse<Product[]>>(params).pipe(
      filter((response: ApiResponse<Product[]>) => !!response.data),
      finalize(() => {
        this.isLoading.set(false);
        this.isLoaded.set(true);
      })
    );
  }
}
