import { Component, OnInit, WritableSignal, computed, inject, signal } from '@angular/core';
import { provideNativeDateAdapter } from '@angular/material/core';
import { SignalsStoreService } from '../../signals-store.service';
import { AccountService } from '../../account.service';
import { FormsModule } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from '../../notification/notification.service';
import { OnlyNumberDirective } from '../../directives/only-number.directive';
import { AddressService } from '../../address.service';
import { CheckAddressSignupComponent } from '../../../authentication/signup/check-address-signup/check-address-signup.component';
import { environment } from '../../../../environments/environment';
import { ModalContentTypes } from '../../constants/modal-content-types';
import { ModalContentService } from '../../modal-content/modal-content.service';

@Component({
    selector: 'app-prospect',
    providers: [provideNativeDateAdapter(), CheckAddressSignupComponent],
    imports: [FormsModule, OnlyNumberDirective],
    templateUrl: './prospect.component.html'
})
export class ProspectComponent implements OnInit {
  private modalContentService = inject(ModalContentService)
  signalsStoreService = inject(SignalsStoreService);
  accountService = inject(AccountService)
  activeModal = inject(NgbActiveModal)
  #modalService = inject(NgbModal);
  notificationService = inject(NotificationService)
  applicationEmail: string = '';

  prospectForm: WritableSignal<any> = signal({
    firstName: '',
    lastName: '',
    email: '',
    zipCode: this.signalsStoreService.googleAddress()?.zipCode || '',
  })

  isValidFormProspectInformation = computed(() => this.validateProspectInfoForm());
  prospectInformationValidations = new Map<string, WritableSignal<{ valid: boolean, error: string, validations: string[] }>>();

  computedZipCode = computed(() => this.signalsStoreService.googleAddress()?.zipCode || null)

  constructor(private addressService: AddressService) { }

  ngOnInit(): void {
    this.applicationEmail = environment.config.contactEmail;
    this.setUpPersonalInfoValidations();
  }

  updateProspectForm(newValue: any, formValidationKey: string) {
    this.prospectForm.update((value) => ({ ...value, ...newValue }))
    // Validate form fields:
    const key = Object.keys(newValue)[0];
    const value = newValue[key];
    this.validateProspectInfoField(formValidationKey, value);
  }

  private validateProspectInfoForm() {
    const formFilled = !!(
      this.prospectForm()?.firstName &&
      this.prospectForm()?.lastName &&
      this.prospectForm()?.email &&
      this.prospectForm()?.zipCode
    );

    if (!formFilled) return false;

    let valid = true;
    const keys: string[] = Array.from(this.prospectInformationValidations.keys());
    for (const key of keys) {
      if (!this.prospectInformationValidations.get(key)?.()?.valid) {
        valid = false;
        break;
      }
    }

    return valid;
  }

  private setUpPersonalInfoValidations() {
    const defaultValue = { valid: true, error: '', validations: ['required'] };
    this.prospectInformationValidations.set('firstName', signal(defaultValue));
    this.prospectInformationValidations.set('lastName', signal(defaultValue));
    this.prospectInformationValidations.set('email', signal({ ...defaultValue, validations: [...defaultValue.validations, 'email'] }));
    this.prospectInformationValidations.set('zipCode', signal({ ...defaultValue, validations: [...defaultValue.validations, 'zipCode'] }));
  }

  validateProspectInfoField(key: string, value: string | any) {
    if (typeof value !== 'string')
      value = value.target.value;
    const entry = this.prospectInformationValidations.get(key);
    if (!entry) return;
    const data = entry();
    for (const validation of entry().validations) {
      switch (validation) {
        case 'required':
          data.error = !value.trim() ? 'This field is required' : ''
          break;
        case 'zipCode':
          data.error = !(/^\d{5}$/.test(value)) ? 'Enter a valid 5-digit number' : '';
          break;
        case 'email':
          data.error = !(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{1,6}$/.test(value)) ? 'Enter a valid email' : ''
          break;
        default:
          break;
      }
    }

    this.prospectInformationValidations.set(key, signal({ valid: !data.error, error: data.error, validations: data.validations }));

  }

  addProspect() {
    const prospectData = this.setupProspectData();
    this.accountService.addProspect(prospectData)
      .subscribe({
        next: (response: any) => {

          if (response?.data) {

            const modalRef = this.modalContentService.openModal(
              ModalContentTypes.SEND_INFORMATION,
              {
                title: 'Thank you!'
              }
            );
            modalRef
              .closed
              .subscribe(() => this.#modalService.dismissAll());
          }

          this.activeModal.close();
        },
      })
  }

  selectPickupOption() {
    this.addressService.setFirstPickUpMembershipSelected()
    this.activeModal.close(false)
  }

  private setupProspectData() {
    return { address: this.signalsStoreService.googleAddress().street, ...this.signalsStoreService.googleAddress(), ...this.prospectForm() }
  }
}
